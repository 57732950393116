<template>
  <div>
    <div class="modal_inner modal_inner--icon">
      <div class="box_select_icon">
        <p class="ttl">アイコン選択</p>
        <ul class="lst_icon">
          <li v-for="val in icon" :key="val">
            <figure>
              <img :src="val.img" />
            </figure>
            <button class="btn_select" v-on:click.prevent="selectIcon(val.img)">
              選択する
            </button>
          </li>
        </ul>
        <button class="btn_modal_close" @click="$emit('close')">
          <img
            src="../assets/images/parts/ico_close.svg"
            width=""
            height=""
            alt="閉じる"
          />閉じる
        </button>
        <button class="btn_modal_close_right" @click="$emit('close')">
          <img
            src="../assets/images/parts/ico_close.svg"
            width=""
            height=""
            alt="閉じる"
          />
        </button>
      </div>
    </div>
    <div class="modal_outer" @click="$emit('close')"></div>
  </div>
</template>

<script>
export default {
  name: "modalIcon",
  computed: {
    icon() {
      return this.$store.getters.getIcon;
    },
  },
  methods: {
    selectIcon(item) {
      this.$store.dispatch("changeIcon", item);
      this.$emit("close");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css" src="@/assets/css/style_parts.css" scoped></style>
<style lang="css" src="@/assets/css/style_mypage.css" scoped></style>
