<template>
  <header class="header_under mypage">
    <router-link to="/" v-on:click="this.$clearAllIntervals()">
      <div class="logo">
        <p class="name">MOG × BR蒲郡</p>
      </div>
    </router-link>
    <!--user-->
    <div class="user_name">
      <figure class="user_icon">
        <img
          :src="info.icon ? info.icon : Icon[0].img"
          width=""
          height=""
          alt=""
        />
      </figure>
      <button class="btn_select" @click="isShow = !isShow">
        アイコンを選択
      </button>
      <h1 class="name">{{ info.nickname }}</h1>
    </div>
    <transition name="fade">
      <modalIcon v-show="isShow" @close="closeModal" />
    </transition>

    <!--背景変更-->
    <button class="btn_menu">
      <div
        class="navToggle"
        v-on:click="menuON = !menuON"
        v-bind:class="{ active: menuON }"
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      <p class="menu_txt" v-on:click="menuON = !menuON">MENU</p>
    </button>
    <transition v-if="loginStatus" name="fade" v-show="menuON">
      <menuLogin />
    </transition>
    <transition v-else name="fade" v-show="menuON">
      <menuLogout />
    </transition>
  </header>
</template>

<script>
import { mapGetters } from "vuex";
import modalIcon from "@/components/modalIcon.vue";
import menuLogin from "@/components/menuLogin.vue";
import menuLogout from "@/components/menuLogout.vue";
export default {
  name: "HeaderSub",
  components: {
    modalIcon,
    menuLogin,
    menuLogout,
  },
  data() {
    return {
      menuON: false,
      isShow: false,
      loginStatus: true,
    };
  },
  computed: {
    ...mapGetters({
      info: "getMemberInfo",
      Icon: "getIcon",
    }),
  },
  methods: {
    closeModal() {
      this.isShow = false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css" src="@/assets/css/style_header.css" scoped></style>
<style lang="css" src="@/assets/css/style_mypage.css" scoped></style>
